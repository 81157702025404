<template>
  <div class="mdl-grid stile-max-width">
    <div class="mdl-cell mdl-cell--12-col mdl-card mdl-shadow--4dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text">UX Designer</h2>
      </div>
      <div class="mdl-card__media">
        <img class="article-image" src="images/ux_design_grande.jpeg" alt="">
      </div>
      <div class="mdl-card__supporting-text">
        <span>Progettazione della <strong>User Experience</strong> e sviluppo del frontend</span>
      </div>
      <div class="mdl-grid stile-copy">
        <h3 class="mdl-cell mdl-cell--12-col mdl-typography--headline">Introduzione</h3>
        <div class="mdl-cell mdl-cell--6-col mdl-card__supporting-text no-padding">
          <p>
            Si progettano interfacce e servizi con un approccio fortemente orientato all'utente, adottando una
            metodologia partecipativa.
          </p>
          <p>
            Si tiene conto delle motivazioni, degli scopi e dei valori di tutti gli attori in gioco, specialmente
            degli utenti.
          </p>
          <p>
            Durante la progettazione, si cerca di dedicare almeno una fase di ricerca con un campione di utenti e
            di esperti di dominio.
          </p>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <img class="article-image" src="images/intervista.jpeg">
        </div>
        <h3 class="mdl-cell mdl-cell--12-col mdl-typography--headline">Progettazione</h3>
        <div class="mdl-cell mdl-cell--6-col">
          <img class="article-image" src="images/fogli_mac.jpeg">
        </div>
        <div class="mdl-cell mdl-cell--6-col mdl-card__supporting-text no-padding ">
          <p>
            Si parte da un <strong>design brief</strong> che comprende sia l'analisi con gli
            stakeholder che l'analisi del dominio, per raggiungere una <strong>vision</strong>
            condivisa.
          </p>
          <p>
            Si individuano gli oggetti che compongono il dominio e quali processi in grado
            di interagire con gli oggetti, sono attivati.
          </p>
          <p>
            Dopo l'individuazione dei <strong>personas</strong> e degli scenari nei quali
            operano, si passa al <strong>design concept</strong> e alla creazione di
            prototipi, per poi giungere alla fine alla stesura di un report che descrive
            il lavoro di progettazione per la soluzione dei <strong>pain point</strong>.
          </p>
        </div>
        <h3 class="mdl-cell mdl-cell--12-col mdl-typography--headline">Tecniche</h3>
        <div class="mdl-cell mdl-cell--6-col">
          <img class="article-image" src="images/post-it.jpeg" alt="">
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <img class="article-image" src="images/albero.jpeg" alt="">
        </div>
        <div class="mdl-cell mdl-cell--12-col mdl-card__supporting-text no-padding ">
          <p>
            Si usano tecniche per ottenere dati e informazioni, quali il <strong>free listing</strong> o il
            <strong>card sorting</strong> per capire associazioni e raggruppamenti di concetti.
          </p>
          <p>
            Il lavoro per offrire una buona esperienza d'uso all'utente non sarebbe completo senza la valutazione
            di programmi, sistemi, macchine ecc... in relazione a come sono utilizzabili. Per questo si effettuano
            <strong>valutazioni euristiche</strong> e <strong>cognitive walkthrough</strong> sui sistemi dei clienti
            o dei concorrenti.
          </p>
        </div>
        <div class="mdl-card__actions mdl-card--border">
          <a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect mdl-button--accent" @click="$router.back()">TORNA INDIETRO</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "UXDesignerEsteso",
  mounted: function() {
    this.$emit("navigazione", "ux_design_esteso");
  }
}
</script>