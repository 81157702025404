<template>
  <div id="app">
      <Cornice :sezioni="sezioni"
               :proprietario="proprietario"
               :sottotitolo="sottotitolo">

      </Cornice>
  </div>
</template>

<script>
import Cornice from "@/components/library/Cornice";

export default {
  name: 'App',
  components: {
    Cornice
  },
  data: function() {
    return {
       // elenco di sezioni con il titolo mostrato nella navigazione e il cammino da seguire nel router
      sezioni: [
        { id: 0,  titolo: "Designer", cammino: "ux_design" },
        { id: 1, titolo: "Informatico", cammino: "sviluppatore_sistemista" },
        { id: 2, titolo: "Libra", cammino: "libra" },
        { id: 3, titolo: "Autore", cammino: "chi_sono" },
        { id: 4, titolo: "Contatti", cammino: "contatti" }
      ],
      sottotitolo: "Progettazione e Sviluppo di Servizi Software",
      proprietario : 'Fatto da <a href="mailto:paoloproni@gmail.com">Paolo Proni</a> - P.IVA: 02376180390'
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
