<template>
  <div class="mdl-grid stile-max-width">
    <scheda immagine="images/chicchi.jpg"
            titolo="Java Standard Edition"
            testo="Si implementano programmi in Java pensati per girare sui desktop dei principali sistemi operativi. L'approccio preferito segue i dettami della programmazione a oggetti. Si possono creare programmi modulari che seguono le specifiche OSGI e si programmano anche macchine industriali con architetture diverse da un comune PC.">
    </scheda>
    <scheda immagine="images/wildfly.png"
            titolo="Java Enterprise Edition"
            testo="L'esperienza significativa con le specifiche di Java Enterprise, consentono di progettare e realizzare progetti di una certa complessità con questa tecnologia. Come application server preferito si consiglia Wildfly per il quale si offre un servizio di configurazione. Lo stack applicativo preferito prevede PostgreSQL come database di riferimento. Si realizzano anche progetti che usano code di messaggi. Si è usato JSF, ma esiste la possibilità di adottare soluzioni frontend più recenti.">
    </scheda>
    <scheda immagine="images/frontend.jpg"
            titolo="Front End"
            testo="Si implementano interfacce web, principalmente basate sul framework Vue.js, quindi usando tecnologie quali HTML, CSS e Javascript. Esperienze positive ci sono state anche con il framework JSF con librerie di terze parti. In passato si è lavorato anche con JSP e servlet, quindi è possibile calarsi in progetti esistenti con tecnologie più datate.">
    </scheda>
    <scheda immagine="images/smartphone.jpg"
            titolo="Mobile app development"
            testo="Si sviluppano app native su piattaforma Android, oltre che web app con interfaccia responsive visualizzabili su smartphone. Si sviluppano interfacce con tecnologia web fruibili anche su dispositivi iOS.">
    </scheda>
    <scheda immagine="images/manimac.jpg"
            titolo="Sviluppo"
            testo="Si sono studiati e usati in diversi contesti linguaggi: C, C++, Python, PHP, Basic, Delphi, PASCAL, DBXL. Breve esperienza con librerie native in C++ su Android. Si fornisce consulenza per lo sviluppo di applicazioni nonché per l'uso e l'ottimizzazione di database relazionali e NoSQL, quali Firebase. ">
    </scheda>
    <scheda immagine="images/htop.jpg"
            titolo="Open Source"
            testo="Il nome Byteliberi nasce da una storica passione e interesse per il software libero e per l'open source. La partecipazione in diversi Linux User Group e la lettura di riviste e documentazione hanno accompagnato la consulenza per clienti aziendali. Il sistema consigliato per i server è CentOS, per il quale si offrono servizi di installazione e configurazione dei servizi principali.">
    </scheda>
  </div>
</template>

<script>
import Scheda from "@/components/library/Scheda";

export default {
  name: "SviluppatoreSistemista",
  components: {
    Scheda
  },
  mounted: function() {
    this.$emit("navigazione", "sviluppatore_sistemista");
  }
}
</script>