<template>
    <div class="mdl-grid stile-max-width">
        <scheda immagine="images/ux_design.jpeg"
                titolo="User Experience Design"
                testo="Si progettano interfacce e servizi con un approccio fortemente orientato all'utente, adottando una metodologia partecipativa."
                :collegamenti="[{ id: 1,
                                  indirizzo: '/ux_design_esteso',
                                  etichetta: 'LEGGI TUTTO'}]">
        </scheda>
        <scheda immagine="images/material.png"
                titolo="Material Design"
                testo="Si possono progettare e realizzare interfacce che seguono i dettami di Material Design. Si riprogettano vecchie interfacce per adeguarle a tali linee guida. L'ispirazione è tratta dal mondo fisico, per creare esperienze digitali di qualità. ">
        </scheda>
        <scheda immagine="images/user_centered_design.jpg"
                titolo="User Centered Design"
                testo="Prima di trovare le soluzioni, si ascoltano le persone per capire i loro bisogni e trovare il modo di aiutarle. A tal proposito si ricorre a interviste e questionari. Si servono gli utenti, non la tecnologia.">
        </scheda>
    </div>
</template>

<script>
import Scheda from "@/components/library/Scheda";

export default {
  name: "UXDesign",
  components: {
    Scheda
  },
  mounted: function() {
    this.$emit("navigazione", "ux_design");
  }
}
</script>