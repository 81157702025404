<template>
  <footer class="mdl-mini-footer">
    <div class="mdl-mini-footer__left-section">
      <div class="mdl-logo" v-html="proprietario"></div>
    </div>
    <div class="mdl-mini-footer__right-section">
      <ul class="mdl-mini-footer__link-list">
        <li><a href="#" @click.prevent="scaricaPrivacy">Privacy</a></li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PieDiPagina",
  props: {
    proprietario: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    scaricaPrivacy() {
      window.open('privacy.pdf', '_blank');
    }
  }
}
</script>