<template>
  <div class="mdl-cell mdl-card mdl-shadow--4dp stile-card">
    <div class="mdl-card__media">
      <img class="article-image" :src="immagine"  alt="">
    </div>
    <div class="mdl-card__title">
      <h2 class="mdl-card__title-text">{{ titolo }}</h2>
    </div>
    <div class="mdl-card__supporting-text">
      {{ testo }}
    </div>
    <!--
    <div class="mdl-card__actions mdl-card--border" v-show="collegamenti">
      <a v-for="collegamento in collegamenti" v-bind:key="collegamento.id"
          class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect mdl-button--accent"
          href="collegamento.indirizzo">{{collegamento.etichetta}}</a>

    </div>
    -->

    <div>
      <router-link v-for="collegamento in collegamenti" v-bind:key="collegamento.id"
                   class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect mdl-button--accent"
                   :to="collegamento.indirizzo">{{collegamento.etichetta}}</router-link>
    </div>
  </div>
</template>

<script>
export default {
name: "Scheda",
  props: {
    immagine: {
      type: String,
      required: true
    },
    titolo: {
      type: String,
      required: true
    },
    testo: {
      type: String,
      required: true
    },
    collegamenti: {
      Type: Array,
      required: false
    }
  }
}
</script>
