<template>
  <div id="cornici" class="mdl-layout mdl-js-layout mdl-layout--fixed-header">
    <!-- Logo e barra dei tag in alto -->
    <header class="mdl-layout__header mdl-layout__header--waterfall stile-testata">
      <div class="mdl-layout__header-row stile-logo-row">
        <div class="mdl-layout__title">
          <div class="stile-logo"></div>
          <span class="mdl-layout__title">{{ sottotitolo }}</span>
        </div>
      </div>
      <div class="mdl-layout__header-row stile-navigation-row mdl-layout--large-screen-only">
        <nav class="mdl-navigation mdl-typography--body-1-force-preferred-font">
          <a v-for="sezione in sezioni" v-bind:key="sezione.id"
             href="#" :class="coloraTag(sezione.cammino)"
             v-on:click.prevent="naviga(sezione.cammino)">{{ sezione.titolo }}</a>
        </nav>
      </div>
    </header>
    <!-- Cassetto per la navigazione laterale -->
    <div id="cassetto" class="mdl-layout__drawer mdl-layout--small-screen-only">
      <nav class="mdl-navigation mdl-typography--body-1-force-preferred-font">
        <a v-for="sezione in sezioni" v-bind:key="sezione.id"
           href="#" :class="coloraItemLaterale(sezione.cammino)"
           v-on:click.prevent="navigaPerCassetto(sezione.cammino)">{{ sezione.titolo }}</a>
      </nav>
    </div>
    <!-- Corpo centrale -->
    <main class="mdl-layout__content">
      <router-view v-on:navigazione="sezioneRaggiunta"></router-view>
      <PieDiPagina :proprietario="proprietario"></PieDiPagina>
    </main>
  </div>
</template>

<script>
import PieDiPagina from "@/components/library/PieDiPagina";

export default {
  name: "ResponsiveMenu",
  components: {
    PieDiPagina
  },
  props: {
    sezioni: {
      type: Array,
      required: true
    },
    proprietario: {
      type: String,
      required: false,
      default: ""
    },
    sottotitolo: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: function() {
    return {
      // Path del componente mostrato nel centro pagina
      cammino: "",
      // Elenco delle sezioni, cioè dei componenti mostrati in centro pagina
      elencoSezioni: this.sezioni,
      // CSS delle voci di navigazione
      MDL_NAVIGATION__LINK: "mdl-navigation__link"
    }
  },
  methods: {
    naviga: function(nuovoCammino) {
      if (this.cammino !== nuovoCammino) {
        this.$router.push({name: nuovoCammino});
      }
    },
    navigaPerCassetto: function(nuovoCammino) {
      this.naviga(nuovoCammino);
      // Come la navigazione con i tag, ma toglie il pannello scuro che serve a fare sparire il drawer
      const layout = document.querySelector('.mdl-layout');
      layout.MaterialLayout.toggleDrawer();
    },
    sezioneRaggiunta: function(event) {
      this.cammino = event;
    },
    coloraTag: function(camminoTag) {
      if (camminoTag === this.cammino) {
        return this.MDL_NAVIGATION__LINK + " is-active";
      }
      else {
        return this.MDL_NAVIGATION__LINK;
      }
    },
    coloraItemLaterale: function(camminoItem) {
      if (camminoItem === this.cammino) {
        return this.MDL_NAVIGATION__LINK + " laterale-attivo";
      }
      else {
        return this.MDL_NAVIGATION__LINK;
      }
    }
  },
  mounted: function() {
    if (this.cammino === "")  // se il cammino è stato inizializzato da un componente mostrato in mezzo al video, non lo deve inizializzare al primo
      this.cammino = this.sezioni[0].cammino;
  }
}
</script>