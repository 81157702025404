<template>
  <div class="mdl-grid stile-autore-width">
    <div class="mdl-cell mdl-cell--12-col mdl-card mdl-shadow--4dp">
      <div class="mdl-card__media">
        <img class="article-image" src="images/io.jpg" alt="">
      </div>
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text">dott. Paolo Proni</h2>
      </div>
      <div class="mdl-grid stile-copy">
        <div class="mdl-cell mdl-cell--12-col mdl-card__supporting-text no-padding">
          <p>
            Laureato in Interfacce e Tecnologie della Comunicazione presso il dipartimento di psicologia e scienze
            cognitive di Trento.
          </p>
          <p>
            Dal 1994 si occupa di consulenza e sviluppo informatico, con una particolare attenzione verso il mondo del
            software libero e dell'open source.
          </p>
          <p>
            Si è occupato sia di formazione che di sviluppare applicazioni per la comunicazione e gestionali di
            tracciabilit&agrave; e magazzino in vari settori, che spaziano dall'agricoltura, all'industria, ai servizi.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChiSono",
  mounted: function() {

    this.$emit("navigazione", "chi_sono");
  }
}
</script>