<template>
  <div class="mdl-grid stile-contatti-width">
    <div class="mdl-cell mdl-cell--12-col mdl-card mdl-shadow--4dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text">Contatti</h2>
      </div>
      <div class="mdl-grid stile-copy">
        <div class="mdl-cell mdl-cell--12-col mdl-card__supporting-text no-padding">
          <p>
            Possiamo fare progetti insieme, potete contattarmi
          </p>
        </div>
        <div>
          <div class="mdl-card__supporting-text">
            <a href="mailto:paoloproni@gmail.com"><img src="images/email.png" title="invia un'email"
                                                 style="width: 24px; margin-right: 24px"></a>
            <a href="https://www.facebook.com/paolo.proni"><img src="images/facebook.png" title="Invia un messaggio via Facebook"
                                                    style="width: 24px; margin-right: 24px"></a>
            <a href="https://www.linkedin.com/in/paolo-proni-60020713"><img src="images/linkedin.png" title="Invia un messaggio via Linkedin"
                                                    style="width: 24px; margin-right: 24px"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Contatti",
  mounted: function() {
    this.$emit("navigazione", "contatti");
  }
}
</script>