<template>
  <div class="mdl-grid stile-max-width">
    <div class="mdl-cell mdl-cell--12-col mdl-card mdl-shadow--4dp">
      <div class="mdl-card__title">
        <h2 class="mdl-card__title-text">Controllo di produzione, magazzino e tracciabilit&agrave; in Cloud</h2>
      </div>
      <div class="mdl-card__media">
        <img class="article-image" src="images/banner_libra.png" alt="">
      </div>
      <div class="mdl-card__supporting-text">
        Libra: dalle bilance al cloud
      </div>
      <div class="mdl-grid stile-copy">
        <h3 class="mdl-cell mdl-cell--12-col mdl-typography--headline">A chi è rivolto</h3>
        <div class="mdl-cell mdl-cell--6-col mdl-card__supporting-text no-padding">
          <p>
            Libra si applica ad aziende piccole e medie, che non vogliono spendere una fortuna in consulenza e che
            preferiscono sentirsi libere di scegliere le proprie procedure, senza essere comandate dal
            progettista del gestionale.
          </p>
          <p>
            Siamo nell'epoca dell'Internet delle cose (IOT) e grazie a Libra, anche vecchie bilance progettate
            anni fa, nate prima di Internet, possono comunicare, affinché i dati possano attraversare enormi
            distanze.
          </p>
          <p>
            Gestire il magazzino diventa semplice e veloce con Libra Mobile: uno strumento potente ed efficace,
            sempre a portata di mano. Libra Mobile è un'app pensata per le operazioni di magazzino, funziona
            su macchine Android, abbinata a lettori di codici a barre, collegati via Bluetooth.
          </p>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <img class="article-image" src="images/schema_libra.png">
        </div>
        <div class="mdl-card__actions mdl-card--border">
          <a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect mdl-button--accent" @click.prevent="scaricaPrivacy">SCARICA LA BROCHURE</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Libra",
  mounted: function() {
    this.$emit("navigazione", "libra");
  },
  methods: {
    scaricaPrivacy() {
      window.open('libra.pdf', '_blank');
    }
  }
}
</script>