import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import SviluppatoreSistemista from "@/components/central-sections/SviluppatoreSistemista";
import UXDesign from "@/components/central-sections/UXDesign";
import Libra from "@/components/central-sections/Libra";
import ChiSono from "@/components/central-sections/ChiSono";
import Contatti from "@/components/central-sections/Contatti";
import UXDesignerEsteso from "@/components/central-sections/UXDesignerEsteso";

Vue.use(VueRouter)
Vue.config.productionTip = false

Vue.component('router-link', Vue.options.components.RouterLink)
Vue.component('router-view', Vue.options.components.RouterView)

const router = new VueRouter({
  mode: 'hash', // se metti history, Apache cerca nelle sottodirectory
  routes: [
    {
      path:'/sviluppatore_sistemista', name:'sviluppatore_sistemista', component: SviluppatoreSistemista
    },
    {
      path:'/ux_design', name:'ux_design', component: UXDesign
    },
    {
      path:'/libra', name:'libra', component: Libra
    },
    {
      path:'/chi_sono', name:'chi_sono', component: ChiSono
    },
    {
      path:'/contatti', name:'contatti', component: Contatti
    },
    {
      path:'/ux_design_esteso', name:'ux_design_esteso', component: UXDesignerEsteso
    },
    {
      path:'*', component: UXDesign
    }
  ]
})

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
